import * as React from "react";

import * as styles from "./styles.module.scss";

const ConnectBlock = () => (
  <article className={styles.connectBlock}>
    <h1>connect</h1>

    <div className={styles.connectWrapper}>
      <div className={styles.connectRole}>publicity</div>
      <div className={styles.connectInfo}>
        <strong>Lydia Liebman</strong>
        <br />
        <a href="mailto:lydia@lydialiebmanpromotions.com">
          lydia@lydialiebmanpromotions.com
        </a>
      </div>
    </div>

    <div className={styles.connectWrapper}>
      <div className={styles.connectRole}>booking &amp; management</div>
      <div className={styles.connectInfo}>
        <a href="mailto:booking@jessefischer.com">booking@jessefischer.com</a>
      </div>
    </div>
  </article>
);

export default ConnectBlock;
